import React, { useEffect } from "react";

const RedirigirConAutenticacion = ({ price, promocionUrl }) => {
  // Función para redirigir al usuario al sitio de autenticación
  const redirigirAAutenticacion = (urlPromo) => {
    // Guardar la URL de destino en sessionStorage
    sessionStorage.setItem("destinoUrl", urlPromo);

    // URL de autenticación
    const urlAutenticacion = price;

    // Abrir la página de autenticación en una nueva ventana
    const autenticacionWindow = window.open(
      urlAutenticacion,
      "_blank",
      "width=800,height=600"
    );

    // Revisa cada 1 segundo si la ventana se ha cerrado
    const checkWindowInterval = setInterval(() => {
      //   if (autenticacionWindow.closed) {
      clearInterval(checkWindowInterval);
      redirigirSiAutenticado();
      //   }
    }, 1000);
  };

  // Función para redirigir al destino una vez autenticado
  const redirigirSiAutenticado = () => {
    const destinoUrl = sessionStorage.getItem("destinoUrl");
    if (destinoUrl) {
      window.location.href = destinoUrl;
      sessionStorage.removeItem("destinoUrl"); // Limpiar la URL después de redirigir
    }
  };

  return (
    <div>
      <h2>Redirigir a una promoción</h2>
      <button onClick={() => redirigirAAutenticacion(promocionUrl)}>
        Ver promoción
      </button>
    </div>
  );
};

export default RedirigirConAutenticacion;
